import { RuleRange, TraceRangeObject } from './trace.types';
const DEFAULT_COLOR = 'white';

//TODO: Will remove this when position not have color inside
export function PositionHack(
  positionWithColor: string,
  newTemplateColor: string
) {
  if (positionWithColor === null) return null;
  return [positionWithColor.split(',').slice(0, 2), newTemplateColor].join(',');
}

//TODO: Move these functions in a seperate file for the trace helpers
export function RangeCalculator(range: RuleRange[], val: string): string {
  if (!range || !val) return DEFAULT_COLOR;
  return (
    range.map((r) => RangeValidator(r, val)).filter((c) => !!c)[0] ||
    DEFAULT_COLOR
  );
}

export function RangeValidator(range: RuleRange, val: string): string | null {
  const { min, max, color } = range;

  if (val === null || val === undefined) return null;

  if (Number(val) >= Number(min) && Number(val) <= Number(max)) {
    return color;
  }

  return null;
}

export function RelativeValueCalculator(
  colorRanges: string[],
  vals: number[],
  minIncrement: number = null
): TraceRangeObject & { colors: string[] } {
  const colorsLength = colorRanges.length;
  const max = Math.max(...vals);
  const min = Math.min(...vals);
  let incrementValue = (max - min) / colorsLength;
  //check for min increment vs range.
  incrementValue = SelectIncrementValue(incrementValue, minIncrement);

  const ranges: RuleRange[] = [];
  let currentMin = parseFloat(min.toFixed(1));

  for (let i = 0; i < colorsLength; i++) {
    const color = colorRanges[i];
    const max = parseFloat((currentMin + incrementValue).toFixed(1));
    ranges.push({ min: currentMin, max, color });
    currentMin = max;
  }
  return {
    range: ranges,
    colors: vals.map((v) => RangeCalculator(ranges, v.toString())),
  };
}

export function SelectIncrementValue(
  incrementValue: number,
  minIncrement: number = null
): number {
  if (minIncrement && minIncrement > 0 && incrementValue < minIncrement)
    return minIncrement;
  return incrementValue;
}
