import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorExceptionFragmentsEnum } from 'src/app/modules/auth/auth.fragments';
import { AuthenticationService } from '../authentication/authentication.service';
import { NotificationsService } from '@core/services/helpers/notifications.service';
import { IGNORED_STATUSES, IGNORED_VALUE } from '@core/api/common.api';
@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements HttpInterceptor {
  private _notifications = inject(NotificationsService);
  private _authService = inject(AuthenticationService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const ignoredStatuses = request.context.get(IGNORED_STATUSES);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error?.message || error.error?.detail;

        this.handleStatus(errorMessage, error.status);

        if (ignoredStatuses.includes(IGNORED_VALUE)) {
          return of();
        }

        return throwError(() => errorMessage);
      })
    );
  }

  private async handleStatus(data, statusCode: number) {
    console.log({ data, statusCode });
    const error = this.errorParser(data);
    let message = error.message;
    const FORBIDDEN_CODE = 403;

    const RESET_PASSWORD_CODE = 452;

    if (statusCode === FORBIDDEN_CODE) return;

    if (statusCode === RESET_PASSWORD_CODE) message = error?.message;

    const CODES_TO_HANDLE = [401, 452, 453, 454, 455, 456];

    if (!CODES_TO_HANDLE.includes(statusCode)) return;

    this._notifications.error('Error', message || 'Api Error');

    await this.sleep(0.1);

    if (statusCode === 401) {
      this._authService.logout();
      return;
    }
    //
    if (statusCode === 455) {
      this._authService.agentExpiration();
      return;
    }
    if (statusCode === 456) {
      this._authService.expiration(ErrorExceptionFragmentsEnum.mac_err);
      return;
    }
    if (statusCode === 454) {
      this._authService.expiration(ErrorExceptionFragmentsEnum.lic_exp);
      return;
    }
    if (statusCode === 453) {
      this._authService.expiration(ErrorExceptionFragmentsEnum.no_lic);
      return;
    }
    if (statusCode === 452) {
      this._authService.resetPassword(data);
      return;
    }
  }

  private errorParser(error) {
    try {
      if (error.message) return error;
      return JSON.parse(error);
    } catch (err) {
      return { message: error };
    }
  }

  private sleep(seconds: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, seconds * 1000);
    });
  }
}
