import { inject, Injectable } from '@angular/core';
import { NotificationsService } from '@core/services/helpers/notifications.service';
import { WindyTypes } from '@dashboard/dashboard-store';
import L from 'leaflet';
import { Layer, Map } from 'leaflet';
import moment from 'moment';
import { interval, Subscription, timer } from 'rxjs';

const INITIAL_WEATHER_DATA: { [K in WindyTypes]: any } = {
  wind: null,
  wave: null,
  currents: null,
};
@Injectable({
  providedIn: 'root',
})
export class WeatherDataService {
  private _notifictions = inject(NotificationsService);
  private _map: Map;
  private $WeatherDataTimer: Subscription;
  private Layers: { windyLayer: Layer } = { windyLayer: null };

  private MapWindyData = { ...INITIAL_WEATHER_DATA };

  public set map(map: Map) {
    this._map = map;
  }

  public setWeatherData(key: WindyTypes, data: any) {
    console.debug('Set weather data', key, data);
    this.MapWindyData[key] = data;
  }

  public InitWeatherDataTimer(cb: () => void) {
    this.StopWeatherDataTimer();
    console.debug('Weather Timer Started');

    //Start the interval in rangeToEndOfHour And repeat every AnHourMilliseconds

    const rangeToEndOfHour = moment().endOf('hour').diff(moment());
    const AnHourMilliseconds = 3_600_000;

    this.$WeatherDataTimer = timer(rangeToEndOfHour).subscribe(() => {
      cb(); // Execute at the end of the hour

      // Start an hourly interval after the initial end-of-hour execution
      this.$WeatherDataTimer = interval(AnHourMilliseconds).subscribe(() => {
        cb();
      });
    });
  }

  public StopWeatherDataTimer() {
    if (!this.$WeatherDataTimer) return;
    console.debug('Weather Timer Stopped');
    this.$WeatherDataTimer.unsubscribe();
    this.$WeatherDataTimer = null;
  }

  public showWindyLayer(windyType: WindyTypes) {
    console.log('showWindyLayer', windyType);
    let data: any;

    const WeatherTypeDataASsigment: { [K in WindyTypes]: any } = {
      wind: this.MapWindyData.wind,
      wave: this.MapWindyData.wave,
      currents: this.MapWindyData.currents,
    };

    this.hideWindyLayer();

    console.log({ windyType, WeatherTypeDataASsigment });
    try {
      data = WeatherTypeDataASsigment[windyType];
      console.log({ windyType, WeatherTypeDataASsigment, data });
    } catch (error) {
      this.hideWindyLayer();
    }

    if (!data) {
      console.error(`NO DATA FOR LAYER: ${windyType}`);
      return;
    }

    const velocityLayer2 = (L as any).velocityLayer({
      displayValues: true,
      displayOptions: {
        velocityType: `${windyType}`,
        position: 'bottomleft',
        emptyString: `No ${windyType} data`,
        showCardinal: true,
      },
      data,
      minVelocity: 0,
      maxVelocity: 25,
      velocityScale: 0.007,
      colorScale: ['green', 'white', 'red'], // ['dark','green','red']
      opacity: 1,
      particleMultiplier: 1 / 300,
      lineWidth: 0.9,
    }) as L.Layer;

    setTimeout(() => {
      this._map.addLayer(velocityLayer2);
      console.debug(`WINDY LAYER ADDED: ${windyType}`);

      this.Layers.windyLayer = velocityLayer2;
    }, 100);
  }

  public hideWindyLayer() {
    if (this.Layers.windyLayer) {
      this._map.removeLayer(this.Layers.windyLayer);
      this.Layers.windyLayer = null;
    }
  }

  public clearWeatherData() {
    console.debug('Weather Data Cleared');
    this.hideWindyLayer();
    this.MapWindyData = { ...INITIAL_WEATHER_DATA };
  }
}
