import { Injectable } from '@angular/core';
import { CommonApiService } from '@core/api/common.api.service';
import { NotificationsService } from '@core/services/helpers/notifications.service';
import { WindyTypes } from '@dashboard/dashboard-store';
import { catchError, forkJoin, throwError } from 'rxjs';
import { EnvVarsService } from 'src/app/env-vars.service';

type WeatherMapper = { [weatherKey in Exclude<WindyTypes, null>]: any };

@Injectable({
  providedIn: 'root',
})
export class WeatherApiService {
  private API_URL = this.environment.getEnvKey('WEATHER_API_URL');
  private namespace = '';
  private url = `${this.API_URL}`;
  constructor(
    private _api: CommonApiService,
    private environment: EnvVarsService,
    private _notif: NotificationsService
  ) {}

  private get ApiSourcesMapper(): WeatherMapper {
    return {
      wind: this.getWind.bind(this),
      wave: this.getWaves.bind(this),
      currents: this.getCurrents.bind(this),
    };
  }

  private getWind(date: string) {
    return this.getWeather(date, 'wind');
  }
  private getWaves(date: string) {
    return this.getWeather(date, 'wave');
  }
  private getCurrents(date: string) {
    return this.getWeather(date, 'currents');
  }
  private getWeather(date?: string, type?: 'wind' | 'wave' | 'currents') {
    return this._api.get(`${this.url}/${date}/${type}`);
  }

  public getWeatherData(
    selectedDate: string,
    weatherType: WindyTypes | 'ALL' = 'ALL'
  ) {
    let ApiSources: Partial<WeatherMapper> = {};
    if (!weatherType || weatherType === 'ALL') {
      ApiSources = {
        wind: this.getWind(selectedDate),
        wave: this.getWaves(selectedDate),
        currents: this.getCurrents(selectedDate),
      };
    } else {
      ApiSources[weatherType] =
        this.ApiSourcesMapper[weatherType](selectedDate);
    }
    return forkJoin(ApiSources).pipe(
      catchError((error) => {
        this._notif.error(
          'Error',
          'Failed to fetch one or more weather data sources'
        );
        return throwError(() => error);
      })
    );
  }
}
