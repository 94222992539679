import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RxjsStateService {
  private _trace$ = new BehaviorSubject<string[]>([]);

  constructor() {}

  public setTrace(trace: string[]) {
    this._trace$.next(trace);
  }
  public get trace$() {
    return this._trace$.asObservable();
  }

  public updateTracePoint(position: string) {
    const trace = this._trace$.getValue();
    if (!trace || trace.length == 0) return [];

    trace.push(position);

    this._trace$.next(trace);
  }
}
