import { inject, Injectable } from '@angular/core';
import { TimestampIsInCurrentMinute } from '@core/constants/time.defaults';
import {
  SetRealtimeFlagAction,
  SetSystemDatesAction,
} from '@dashboard/dashboard-store/actions';
import { ToDateSelector } from '@dashboard/dashboard-store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, switchMap, withLatestFrom } from 'rxjs';
@Injectable()
export class WsEffects {
  private actions$ = inject(Actions);
  private _store = inject(Store);

  HandleRealtimeFlag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetSystemDatesAction),
      withLatestFrom(this._store.select(ToDateSelector)),
      switchMap(([, toDate]) => {
        const isRealTime = TimestampIsInCurrentMinute(toDate);
        return of(SetRealtimeFlagAction({ enableRealtime: isRealTime }));
      })
    );
  });
}
