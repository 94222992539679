import { Injectable } from '@angular/core';
import { adjustTimeToTargetTimezone } from '@core/constants/time.defaults';
import moment, { DurationInputArg2 } from 'moment';
import { BehaviorSubject, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimelineService {
  public timezone$ = new BehaviorSubject<number>(0);
  public dateTo$ = new BehaviorSubject<Date | null>(null);
  public dateFrom$ = new BehaviorSubject<Date>(this.GetTzDateNow());
  public offsetStepSize$ = new BehaviorSubject<IStep>(offsetSteps['d']);
  public isRealtime$ = new BehaviorSubject(true);

  public dateOptionsArray$: BehaviorSubject<DateOptions>;

  public ReferenceDate$: BehaviorSubject<Date>;
  //It is requred in order to produce the dates left and right of the selected date.
  public currentDate$ = new BehaviorSubject(this.GetTzDateNow());

  constructor() {
    this.dateOptionsArray$ = new BehaviorSubject([]);
    this.dateTo$ = new BehaviorSubject(this.currentDate$.getValue());
    this.ReferenceDate$ = new BehaviorSubject(this.currentDate$.getValue());

    const minuteMiliSeconds = 60000;
    const rangeToEndOfMin = moment().endOf('minute').diff(moment());

    /**
     * This timer will start at the end of the current minute and will emit every minute.
     * It will update the currentDate variable.
     * currentDate$ is a variable that holds the current user system date. it should be set only in this timer.
     */
    timer(rangeToEndOfMin, minuteMiliSeconds).subscribe(() => {
      this.currentDate$.next(this.GetTzDateNow());
      this.isRealtime$.getValue() &&
        this.ReferenceDate$.next(this.currentDate$.getValue());
      this.generateOptions();
    });
  }

  generateOptions() {
    const stepSize = this.offsetStepSize$.getValue();
    const refDate = this.ReferenceDate$.getValue();
    const tempArray: DateOptions = [];
    const currentDate = this.currentDate$.getValue();

    for (let index = -3; index <= 3; index++) {
      //THese dates are going to be used allongsize with angular date pipe so we need to convert them to date.
      //But also in clints timezone. Right now refDate is in ??
      //TODO: Is this requeried? i think refDate is have values since it is set in the constructor
      const date = moment(refDate || currentDate).add(index, stepSize.name);

      tempArray.push(date.toDate());
    }
    // console.log({ refDate, currentDate, tempArray });
    this.dateOptionsArray$.next(tempArray);

    return tempArray;
  }

  public stepsArray = Object.values(offsetSteps);

  isPast(date: Date) {
    const currentTimestamp = this.GetTzDateNow();

    const isInCurrentMinute = moment(date).isSameOrAfter(
      moment(currentTimestamp),
      'minute'
    );

    this.isRealtime$.next(isInCurrentMinute);
    return !isInCurrentMinute;
  }

  setDate(date: Date | null, type?: 'next' | 'prev') {
    let tempToDate: Date = this.dateTo$.getValue();
    let tempFromDate: Date = this.dateFrom$.getValue();

    //? Determine if we need to apply DST adjustment winter time checking
    const dstTo = moment(tempToDate).isDST() ? 1 : 0;
    const dstFrom = moment(tempFromDate).isDST() ? 1 : 0;
    const stepSize = this.offsetStepSize$.getValue();

    switch (type) {
      case 'next':
        tempToDate = moment(tempToDate).add(1, stepSize.name).toDate();
        tempFromDate = moment(tempFromDate).add(1, stepSize.name).toDate();

        //? winter time compensation
        tempToDate = moment(tempToDate).subtract(dstTo, 'h').toDate();
        tempFromDate = moment(tempFromDate).subtract(dstFrom, 'h').toDate();

        break;

      case 'prev':
        // Check if the date is within the DST change window

        // Move the date backward
        tempToDate = moment(tempToDate).subtract(1, stepSize.name).toDate();
        tempFromDate = moment(tempFromDate).subtract(1, stepSize.name).toDate();

        //? winter time compensation
        tempToDate = moment(tempToDate).subtract(dstTo, 'h').toDate();
        tempFromDate = moment(tempFromDate).subtract(dstFrom, 'h').toDate();

        break;

      default:
        tempToDate = date;
        break;
    }

    this.ReferenceDate$.next(tempToDate);
    this.dateTo$.next(tempToDate);
    this.dateFrom$.next(tempFromDate);

    this.generateOptions();
    this.isPast(tempToDate);
  }

  setStepSize(step: IStep) {
    this.offsetStepSize$.next(step);

    this.generateOptions();
  }

  setToDate(date: Date) {
    this.dateTo$.next(date);

    this.isPast(date);
    this.generateOptions();
  }

  setFromDate(date: Date) {
    this.dateFrom$.next(date);
    this.isPast(date);
    this.generateOptions();
  }

  public GetTzDateNow() {
    return adjustTimeToTargetTimezone(
      new Date(),
      null,
      this.timezone$.getValue()
    ).getDate();
  }
}

export type Steps = Record<any, IStep>;
export type StepSizes = DurationInputArg2;
export interface IStep {
  name: StepSizes;
  format: string;
  splitChar: ':' | '/';
  tooltip: string;
  fullName: 'month' | 'day' | 'year' | 'minute' | 'hour';
}

export type DateOptions = Date[];

export const offsetSteps: Steps = {
  d: {
    name: 'd',
    format: 'd/MMM',
    splitChar: '/',
    tooltip: 'EEEE, HH:mm',
    fullName: 'day',
  },
  M: {
    name: 'M',
    format: 'MMM/YY',
    splitChar: '/',
    tooltip: 'EEEE d, HH:mm',
    fullName: 'month',
  },
  y: {
    name: 'y',
    format: 'YYYY',
    splitChar: '/',
    tooltip: 'd/MMM, HH:mm',
    fullName: 'year',
  },
  h: {
    name: 'h',
    format: 'HH:mm',
    splitChar: ':',
    tooltip: 'EEEE d/MMM/YY',
    fullName: 'hour',
  },
  m: {
    name: 'm',
    format: 'HH:mm',
    splitChar: ':',
    tooltip: 'EEEE d/MMM/YY',
    fullName: 'minute',
  },
};
