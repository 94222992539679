import { Injectable, inject } from '@angular/core';
import { WeatherApiService } from '@core/api/weather-api.service';
import {
  AppInitializeAction,
  ToDateSelector,
  SetSystemDatesAction,
  ToggleWindyLayerAction,
  WindyLayerSelector,
  RealtimeIsActiveSelector,
  SetRealtimeFlagAction,
  WindyTypes,
} from '@dashboard-store';
import {
  CancelWeatherLoadingAction,
  GetWeatherDataAction,
  GetWeatherDataErrorAction,
  GetWeatherDataSuccessAction,
  StartWeatherTimerAction,
  StopWeatherTimerAction,
} from '@dashboard/dashboard-store/actions/weather.actions';
import { DatesHelperService } from '@dashboard/services/dates-helper.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WeatherDataService } from '@widgets/map-widget/services/weather-data.service';
import {
  catchError,
  combineLatest,
  EMPTY,
  filter,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class WeatherEffects {
  private _weatherApi = inject(WeatherApiService);
  private _weather = inject(WeatherDataService);
  private _dateSrv = inject(DatesHelperService);
  private actions$ = inject(Actions);
  private _store = inject(Store);

  private latestApiMeta: Record<WindyTypes, string> = {
    wind: '',
    wave: '',
    currents: '',
  };
  InitializeMapEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppInitializeAction),
      switchMap(() =>
        of(
          GetWeatherDataAction({
            windType: 'ALL',
            source: 'InitializeMapEffect',
          })
        )
      )
    );
  });

  //Load weather data but if while getting the data from api date change we need to stop basicaly cancel it.

  GetWeatherApiData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetWeatherDataAction),
      withLatestFrom(
        this._store.select(ToDateSelector),
        this._store.select(RealtimeIsActiveSelector),
        this._store.select(WindyLayerSelector)
      ),
      switchMap(([{ windType }, toDate, isRealTime, windyType]) => {
        const _weatherDateNow$ = this._dateSrv.getNow();
        const _weatherToDate$ = this._dateSrv.getDateToLocale(toDate);

        return combineLatest([_weatherDateNow$, _weatherToDate$]).pipe(
          filter(([now, to]) => !!now && !!to), // Ensure both values are defined
          take(1), // Emit only once after both observables have emitted
          switchMap(([now, to]) => {
            const weatherToDate = to.toWeatherApiFormat();
            /**
             * The next date variable must be confusing but.... when we are in real time the "toDate" property from state
             * is not the current timestamp since we are not updating all the time. So we need to get the current timestamp
             */
            const _weatherDateApi = isRealTime
              ? now.toWeatherApiFormat()
              : to.toWeatherApiFormat();

            /**
             * We keep the previous weather timestamp. if the current timestamp is the same as the previous one we return empty.
             * We check if is real time because when it is real time this is always true ans always the request is getting canceled.
             */

            if (!isRealTime) {
              if (
                !windType ||
                (windType === 'ALL' &&
                  this.latestApiMeta.wind === weatherToDate)
              )
                return of(CancelWeatherLoadingAction());

              if (this.latestApiMeta[windType] === weatherToDate)
                return of(CancelWeatherLoadingAction());
            }

            return this._weatherApi
              .getWeatherData(_weatherDateApi, windType)
              .pipe(
                switchMap(({ wind, wave, currents }) => {
                  //Every time the requrest completes one or more will have data. if there are data. then we update the latestApiMeta
                  if (wind) this.latestApiMeta.wind = _weatherDateApi;
                  if (wave) this.latestApiMeta.wave = _weatherDateApi;
                  if (currents) this.latestApiMeta.currents = _weatherDateApi;

                  const windyTypesArray: WindyTypes[] = [
                    'wind',
                    'currents',
                    'wave',
                  ];
                  windyTypesArray.forEach((key) => {
                    const weatherKeyData = { wind, wave, currents }[key];
                    if (weatherKeyData) {
                      this._weather.setWeatherData(key, weatherKeyData);
                    }
                  });
                  if (windyType) {
                    this._weather.showWindyLayer(windyType);
                  }
                  return of(
                    GetWeatherDataSuccessAction({
                      wind: [],
                      wave: [],
                      currents: [],
                    })
                  );
                }),
                catchError((error) => of(GetWeatherDataErrorAction(error))),
                // Cancel the request and dispatch CancelWeatherDataLoadingAction if SetSystemDatesAction is triggered
                takeUntil(this.actions$.pipe(ofType(SetSystemDatesAction)))
              );
          })
        );
      })
    );
  });
  CancelWeatherDataOnSystemDateChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetSystemDatesAction),
      map(() => CancelWeatherLoadingAction()) // Dispatch the cancel action to reset loading
    );
  });

  InitializeWeatherTimer$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppInitializeAction, StartWeatherTimerAction),
        map(() => {
          this._weather.InitWeatherDataTimer(this.WeatherCallBack());
        })
      );
    },
    { dispatch: false }
  );

  StopWeatherTimer$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(StopWeatherTimerAction, SetRealtimeFlagAction),
        map((action) => {
          if (action.type === StopWeatherTimerAction.type) {
            this._weather.StopWeatherDataTimer();
            return;
          }
          if (action.type === SetRealtimeFlagAction.type) {
            if (action.enableRealtime) return;
            this._weather.StopWeatherDataTimer();
          }
        })
      );
    },
    { dispatch: false }
  );

  //if toDate changed and is real time then we need to start the weather
  StartTimerWithRealTime$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetRealtimeFlagAction),
      switchMap(({ enableRealtime }) => {
        if (enableRealtime) {
          //We do the api call and we start the timer
          this._weather.InitWeatherDataTimer(this.WeatherCallBack());
          return of(
            GetWeatherDataAction({
              windType: 'ALL',
              source: 'StartTimerWithRealTime',
            })
          );
        }
        return EMPTY;
      })
    );
  });

  //if toDate changed then we need to clear the data and toggle off the wind layers
  clearWeatherData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetSystemDatesAction),
      switchMap(({ toDate }) => {
        const toDateExists = !!toDate;

        if (toDateExists) {
          this._weather.hideWindyLayer();
          this._weather.clearWeatherData();
          this._weather.StopWeatherDataTimer();
        }

        return of(ToggleWindyLayerAction({ windyType: null }));
      })
    );
  });

  toggleWindLayer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ToggleWindyLayerAction),
      withLatestFrom(this._store.select(RealtimeIsActiveSelector)),
      switchMap(([{ windyType }, isRealTime]) => {
        this._weather.showWindyLayer(windyType);

        if (isRealTime || !windyType) return EMPTY;
        return of(
          GetWeatherDataAction({
            windType: windyType,
            source: 'toggleWindLayer',
          })
        );
      })
    );
  });

  private WeatherCallBack() {
    return () => {
      this._store.dispatch(
        GetWeatherDataAction({
          windType: 'ALL',
          source: 'Timer Weather CB',
        })
      );
    };
  }
}
