import { Injectable, inject } from '@angular/core';
import { TooltipsApiService } from '@core/api/tooltip-api.service';
import { DateToItsTimezone } from '@core/constants/time.defaults';
import {
  AppInitializeAction,
  FleetApiActions,
  SetSystemDatesAction,
  SetTooltipAction,
  SetVariablesDataAction,
  TabIsActiveAction,
} from '@dashboard/dashboard-store/actions';
import {
  FromDateSelector,
  TimezoneSelector,
  ToDateSelector,
} from '@dashboard/dashboard-store/selectors';
import { SystemDataErrorsService } from '@dashboard/services/system-data-errors.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import {
  catchError,
  EMPTY,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class SystemEffects {
  private actions$ = inject(Actions);
  private SystemDataError = inject(SystemDataErrorsService);
  private TooltipsApi = inject(TooltipsApiService);
  private _store = inject(Store);

  //this runs only once on init app when FleetApiActions.success() is dispatched
  GetTooltip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppInitializeAction),
      mergeMap(() =>
        this.TooltipsApi.getTooltip().pipe(
          catchError((error) => {
            console.error('Error fetching tooltip:', error);
            return EMPTY;
          })
        )
      ),
      switchMap(({ value }) => {
        return of(SetTooltipAction({ value }));
      })
    );
  });
  IngestionErrorCheck$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FleetApiActions.success),
        map(() => {
          setTimeout(() => {
            this.SystemDataError.IngestionErrorChecker();
          }, 200);
        })
      );
    },
    { dispatch: false }
  );
  ///When ws message run the process to check if the vessel is offline
  RunSystemErrorCheck$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SetVariablesDataAction),
        map((action) => {
          if (action.source === 'WS') {
            setTimeout(() => {
              this.SystemDataError.IngestionErrorChecker();
            }, 100);
          }
        })
      );
    },
    { dispatch: false }
  );

  TabIsActive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TabIsActiveAction),
      withLatestFrom(
        this._store.select(ToDateSelector),
        this._store.select(FromDateSelector),
        this._store.select(TimezoneSelector)
      ),
      switchMap(([, toDate, fromDate, tz]) => {
        const dateNowInTz = DateToItsTimezone(moment(), tz);

        const diffInMinutes = moment
          .duration(moment(dateNowInTz).diff(moment(toDate)))
          .asMinutes();

        const tenMinutesPassed = diffInMinutes > 10;

        return tenMinutesPassed
          ? of(SetSystemDatesAction({ fromDate, toDate: dateNowInTz }))
          : EMPTY;
      })
    );
  });
}
