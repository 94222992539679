import { AggregationStepExport } from '@core/models/export-tool/preset-load-save.model';
import { AggregationStepDashboard } from '@dashboard/dashboard-store';
import moment from 'moment';

/**
 * Calculates the aggregated value based on the aggregation step and type.
 *
 * @param {number} size - The step size for aggregation (e.g., minutes).
 * @param {'m'|'h'|'D'|'undefined'} step - The type of aggregation:
 *        'm' for minutes, 'h' for hours, 'D' for days, or 'undefined' for default.
 * @returns {number} - The calculated aggregated value based on the aggregation type.
 */

const calcAggr = (
  size: number,
  step: AggregationStepDashboard | AggregationStepExport
): number =>
  ({
    m: size,
    h: size * 60,
    D: size * 60 * 24,
    undefined: size,
  })[step];

export default calcAggr;

/**
 * Calculates the aggregation limit and recommends an appropriate aggregation size
 * based on the provided date range, step size, and limit.
 *
 * @param {number} limitSize - The maximum allowable number of sections.
 * @param {string|Date} startDate - The start date of the range.
 * @param {string|Date} endDate - The end date of the range.
 * @param {AggregationStepDashboard | AggregationStepExport} [step] - The aggregation step unit (optional, default: 'm').
 * @param {number} [size] - The initial size for aggregation (optional).
 * @returns {{
 *   currentSize: {value: number, unit: AggregationStepDashboard | AggregationStepExport},
 *   canRequest: boolean,
 *   recommendedSize: {value: number, unit: AggregationStepDashboard | AggregationStepExport}
 * }} - The aggregation result.
 * @throws {Error} - Throws an error if the recommended value exceeds the total period.
 */

export const calcAggregationLimit = (
  limitSize: number,
  startDate: string | Date,
  endDate: string | Date,
  step?: AggregationStepDashboard | AggregationStepExport,
  size?: number
): CalcAggregationLimitResult => {
  const aggregationValue = step ? size : 1; // Initial aggregation value
  const aggregationStep = step ?? 'm'; // Initial aggregation unit (e.g., 'minutes' or 'hours')
  const totalDifference = moment(endDate).diff(moment(startDate), 'm');

  let numberOfSections =
    totalDifference / calcAggr(aggregationValue, aggregationStep);

  // Check if the current aggregation passes the test
  const canRequest = Math.ceil(numberOfSections) <= limitSize;

  // If it doesn't pass, adjust the aggregation
  let recommendedValue = aggregationValue;
  while (Math.ceil(numberOfSections) > limitSize) {
    recommendedValue += 1; // Dynamically adjust aggregation value
    numberOfSections = totalDifference / recommendedValue;

    // Stop if recommendedValue exceeds the total period
    if (recommendedValue > totalDifference) {
      throw new Error(
        'Aggregation value exceeds total period. Cannot reduce sections further.'
      );
    }
  }

  // Prepare the output
  const result: CalcAggregationLimitResult = {
    currentSize: {
      value: aggregationValue,
      unit: aggregationStep,
    },
    canRequest,
    recommendedSize: {
      value: recommendedValue,
      unit: 'm',
    },
  };

  console.log(result);
  return result;
};

// Define the return type for calcAggregationLimit
export type CalcAggregationLimitResult = {
  currentSize: {
    value: number;
    unit: AggregationStepDashboard | AggregationStepExport;
  };
  canRequest: boolean;
  recommendedSize: {
    value: number;
    unit: AggregationStepDashboard | AggregationStepExport;
  };
};
