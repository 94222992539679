import { WindyTypes } from '@dashboard/dashboard-store/dashboard.state';
import { createAction, props } from '@ngrx/store';

enum WeatherActions {
  START_TIMER = '[WEATHER] START_TIMER',
  STOP_TIMER = '[WEATHER] STOP_TIMER',
  GET_WEATHER_DATA = '[WEATHER] GET_WEATHER_DATA',
  GET_WEATHER_DATA_SUCCESS = '[WEATHER] GET_WEATHER_DATA_SUCCESS',
  GET_WEATHER_DATA_ERROR = '[WEATHER] GET_WEATHER_DATA_ERROR',
  CANCEL_WEATHER_LOADING = '[WEATHER] CANCEL_WEATHER_LOADING',
}
const StartWeatherTimerAction = createAction(WeatherActions.START_TIMER);

const StopWeatherTimerAction = createAction(WeatherActions.STOP_TIMER);

const GetWeatherDataAction = createAction(
  WeatherActions.GET_WEATHER_DATA,
  props<{ windType: WindyTypes | 'ALL'; source?: string }>()
);
const GetWeatherDataSuccessAction = createAction(
  WeatherActions.GET_WEATHER_DATA_SUCCESS,
  props<{ [K in WindyTypes]: any }>()
);
const GetWeatherDataErrorAction = createAction(
  WeatherActions.GET_WEATHER_DATA_ERROR,
  props<{ error: any }>()
);

const CancelWeatherLoadingAction = createAction(
  WeatherActions.CANCEL_WEATHER_LOADING
);
export {
  StartWeatherTimerAction,
  StopWeatherTimerAction,
  GetWeatherDataAction,
  GetWeatherDataSuccessAction,
  GetWeatherDataErrorAction,
  CancelWeatherLoadingAction,
};
