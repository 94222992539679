import { ExtraStateIds, SystemTags } from '@dashboard/models';
import { DashboardVarValue } from '@dashboard-store';
import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
enum DashboardDataActions {
  INIT_STATE = '[DASHBOARD] INIT_STATE',
  PARSE_MULTIPLE_VARIABLES = '[DASHBOARD] PARSE_MULTIPLE_VARIABLES',
  SET_MULTIPLE_VARIABLES = '[DASHBOARD] SET_MULTIPLE_VARIABLES',
  PARSE_DOWNSAMPLE_VARIABLES = '[DASHBOARD] PARSE_DOWNSAMPLE_VARIABLES',
}

const InitDashboardStateAction = createAction(
  DashboardDataActions.INIT_STATE,
  props<{ varState: any }>()
);

type SetVariablesPayloadType = [
  vesselImo: string,
  SystemTags | ExtraStateIds,
  DashboardVarValue,
];
const SetVariablesDataAction = createAction(
  DashboardDataActions.SET_MULTIPLE_VARIABLES,
  props<{
    variableMeta: SetVariablesPayloadType[];
    source: 'API' | 'WS';
  }>()
);

const VesselSidebarApiActions = createActionGroup({
  source: 'VESSEL_SIDEBAR_API',
  events: {
    call: emptyProps(),
    success: emptyProps(),
    failure: emptyProps(),
  },
});

const VesselInfoWindoApiActions = createActionGroup({
  source: 'VESSEL_INFO_WINDOW_API',
  events: {
    call: emptyProps(),
    success: emptyProps(),
    failure: emptyProps(),
  },
});

export {
  DashboardDataActions,
  InitDashboardStateAction,
  SetVariablesDataAction,
  SetVariablesPayloadType,
  VesselSidebarApiActions,
  VesselInfoWindoApiActions,
};
