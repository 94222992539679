import { EnvVarsService } from 'src/app/env-vars.service';
import { Injectable, inject } from '@angular/core';
import {
  ActiveTraceConfigSelector,
  ToDateSelector,
  SelectedVesselImo,
  SetActiveTraceAction,
  SetSelectedVesselAction,
  SetSystemDatesAction,
  SetVariablesDataAction,
  TraceSelector,
  UpdateActiveTrace,
  VesselDataSelector,
  FromDateSelector,
} from '@dashboard-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  EMPTY,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { TraceService } from '@dashboard/models/trace/trace.service';
import { TypedAction } from '@ngrx/store/src/models';
import {
  NaviqoreDataApiService,
  ExportGraphRequest,
} from '@core/api/naviqore-data-api.service';
import { TransformTagToId } from '@dashboard/tags-to-ids.transformer';
import { QueryDataDates } from '@core/constants/time.defaults';
import { TraceApiActions } from '../actions/trace.actions';
import calcAggr, { calcAggregationLimit } from '@shared/utils/calcAggregation';

import { RxjsStateService } from '@dashboard/services/rxjs-state.service';

@Injectable()
export class TraceEffects {
  private actions$ = inject(Actions);
  private _store = inject(Store);
  private _traceService = inject(TraceService);
  private _queryDataApi = inject(NaviqoreDataApiService);
  private _envService = inject(EnvVarsService);
  private _rxjsState = inject(RxjsStateService);

  TRACES_LIMIT = Number(this._envService.getEnvKey('TRACES_LIMIT') || 150000);

  trigerTraceApiCall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SetActiveTraceAction,
        SetSelectedVesselAction,
        SetSystemDatesAction
      ),

      mergeMap(() => {
        this._rxjsState.setTrace([]);
        return of(TraceApiActions.call());
      })
    );
  });

  traceRequestsSet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TraceApiActions.call),
      withLatestFrom(
        this._store.select(ActiveTraceConfigSelector),
        this._store.select(ToDateSelector),
        this._store.select(FromDateSelector),
        this._store.select(SelectedVesselImo)
      ),
      mergeMap(([, config, toDate, fromDate, selectedImo]) => {
        this._rxjsState.setTrace([]);
        //If selected vessel change and is not set return easly
        if (!selectedImo) return EMPTY;

        const traceConfig = config;
        const { recommendedSize } = calcAggregationLimit(
          this.TRACES_LIMIT,
          fromDate,
          toDate
        );

        const req: ExportGraphRequest = {
          start: QueryDataDates(fromDate),
          end: QueryDataDates(toDate),
          variables: [
            { tagId: Number(TransformTagToId(traceConfig.templateTag)) },
            { tagId: Number(TransformTagToId(traceConfig.traceTag)) },
          ],
          filters: [],
          aggregation: calcAggr(recommendedSize.value, recommendedSize.unit),
        };

        return this._queryDataApi.getExportGraphApiData(selectedImo, req).pipe(
          // switchMap(() =>
          //   of({
          //     data: [{ tagData: trData.data }, { tagData: trData.coords }],
          //   })
          // ),
          switchMap((traceResponse) => {
            if (!traceResponse?.data) return EMPTY;

            const traceRespData = traceResponse.data;

            const templateRespData = traceRespData[0].tagData as string[];
            const coordData = traceRespData[1].tagData as string[];
            const t0 = performance.now();
            const { traceData, relativeCustomRange } =
              this._traceService.UpdateDashboardLine(
                traceConfig,
                coordData || [],
                templateRespData || []
              );

            const t1 = performance.now();
            console.log(
              'Call to summBrute took ' + (t1 - t0) + ' milliseconds.'
            );

            const actionsArray: TypedAction<any>[] = [];

            this._rxjsState.setTrace(traceData);
            // setTimeout(() => {
            //   console.log('set tracedata');
            //   this._rxjsState.setTrace(traceData);
            // }, 2000);

            this._rxjsState.setTrace(traceData);

            //Ralative Range because for each sleected tag we need to update the color range coding at the bottom right
            if (relativeCustomRange && relativeCustomRange.length > 0) {
              actionsArray.push(
                UpdateActiveTrace({
                  traceConfig: {
                    ...config,
                    range: relativeCustomRange,
                  },
                })
              );
            }
            return of(...actionsArray, TraceApiActions.success());
          }),
          catchError(() => {
            this._rxjsState.setTrace([]);
            return EMPTY;
          })
        );
      })
    );
  });

  //We need this to happens only when its from websockets.
  ExtendActiveTrace$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SetVariablesDataAction),
        withLatestFrom(
          this._store.select(ActiveTraceConfigSelector),
          this._store.select(SelectedVesselImo),
          this._store.select(TraceSelector()),
          this._store.select(VesselDataSelector())
        ),
        map(([action, activeConfig, imo, , activeImoData]) => {
          if (!imo) return EMPTY;
          if (action.source === 'API') return EMPTY;

          const { variableMeta } = action;

          const CoordForActiveTrace = variableMeta.filter(
            ([toFindImo, tag]) => toFindImo === imo && tag === 'COORD'
          );
          const traceTemplateTag = activeConfig.templateTag;
          const TemplateValue = activeImoData[traceTemplateTag].formattedValue;

          if (CoordForActiveTrace.length === 0) return EMPTY;

          const [actionData] = CoordForActiveTrace;
          const [, , position] = actionData;

          const newPosition = this._traceService.addNewValueInLine(
            activeConfig,
            {
              position: position.formattedValue,
              template: TemplateValue,
            }
          );

          this._rxjsState.updateTracePoint(newPosition);
        })
      );
    },
    { dispatch: false }
  );
}
